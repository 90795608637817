@charset 'utf-8';

@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import 'settings';
@import 'foundation';
@import 'motion-ui';
@import "../foundation-icons/foundation-icons.css";
@import 'main'; //magnific-popup

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;


$homework-color: #90CFCF;
$dutch-lessons-color: #ED9975;

@font-face {
    font-family: 'planet_estyleregular';
    src: url('../fonts/plane___-webfont.eot');
    src: url('../fonts/plane___-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/plane___-webfont.woff') format('woff'),
    url('../fonts/plane___-webfont.ttf') format('truetype'),
    url('../fonts/plane___-webfont.svg#planet_estyleregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

.nixhier
{
    display: none !important;
}

.palmedia {
    font-family: "planet_estyleregular", serif;

    font-size: 110%;
    color: $white;
    &:hover {
        color: $dark-gray;
    }
}

@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow;
    box-shadow: $shadow;
}

.off-canvas-wrapper-inner, .off-canvas{
    max-height: 100vh;
}

html, body {
    height:100%;
}

.homepage-top-menu {
    background: $white;
    //@include box-shadow( 0px 0px 2px rgba($primary-color,.4) );
    padding: 20px;
}

.homepage-blocks-bg {
    background: url("../img/homepage/bg-image.jpg") no-repeat bottom center;
    background-size: cover;
}

.bg-dutch-lessons {
    background-color: rgba($dutch-lessons-color,1);
}

.bg-homework {
    background-color: rgba($homework-color,1);
}

.homepage-blocks-bg .bg-dutch-lessons {
    background-color: rgba(#DE492A,1);
}

.homepage-blocks-bg .bg-homework {
    background-color: rgba(#239A98,1);
}

.homepage-block {
    max-width: 300px;
    margin: rem-calc(20) auto;
    @include breakpoint(medium) {
        margin: rem-calc(100) auto;
    }
    @include breakpoint(large) {
        margin: rem-calc(150) auto;
    }
    $block-radius: 25px;
    border-radius: $block-radius;
    -webkit-border-radius: $block-radius;
    -moz-border-radius: $block-radius;
    .homepage-block-header {
        background: $white;
        @include border-radius($block-radius $block-radius 0 0);
        text-align: center;
        margin-bottom: rem-calc(20);
        .logo {
            margin: rem-calc(30) rem-calc(30) rem-calc(10) rem-calc(-20); // center text in logo
            width: rem-calc(150);
        }
        .subtitle {
            font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 15px;
            font-weight: bold;
            text-transform: uppercase;
            padding-bottom: rem-calc(10);
        }
    }
    .homepage-block-body {
        padding: 0 20px 20px 20px;
        color: $white;
        //text-align: left;
        .subtitle {
            font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 15px;
            font-weight: bold;
            text-transform: uppercase;
            padding-top: rem-calc(10);
            cursor: pointer;

        }
        ul {
            //display: none;
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                margin: 0;
                padding: 0;
                a {
                    color: $white;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.home-footer-header {
    display: table;
    table-layout: fixed;
    line-height: 16px;
    width: 100%;
    .home-footer-header-spacer {
        display: table-cell;
        background-color: $white;
        width: auto;
        padding: 0;
        margin: 0;
    }
    .home-footer-header-middle {
        display: table-cell;
        width: 155px;
        padding: 0;
        margin: 0;
        background: url("../img/homepage/footer.svg") no-repeat bottom center;
        background-size: cover;
    }
}

.home-footer {
    background-color: $white;
    ul {
        margin: 0 0 rem-calc(20) 0;
        padding: 0;
        li {
            list-style: none;
            margin: rem-calc(10) 0;
            padding: 0;
            font-size: 14px;
            i {
                margin: 0 rem-calc(10) 0 0;
            }
            a {
                color: $black;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.icon-socials {
    height: 25px;
    margin-left: -5px;
}

.bg-color-bijles {
    background-color: $primary-color-bijles;
}
.bg-color-dutch-lessons {
    background-color: $primary-color-dutch-lessons;
}

.page-top-menu {
    background-color: $white;
    .menu-logo {
        padding: rem-calc(15) 0;
        max-width: 435px;
    }
    .close-button {
        position: relative;
        margin: rem-calc(5) 0;
        i {
            font-size: 25px;
            @include breakpoint(medium) {
                font-size: 45px;
                margin: rem-calc(25) 0;
            }
            @include breakpoint(large) {
                font-size: 50px;
                margin: rem-calc(15) 0;
            }
        }
    }
    ul {
        margin: rem-calc(10) 0 rem-calc(20) 0;
        padding: 0;
        li {
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: right;
            font-size: 20px;
            @include breakpoint(medium) {
                font-size: 24px;
            }
            @include breakpoint(large) {
                font-size: 24px;
            }
            a {
                color: $black;
                &:hover {
                    text-decoration: underline;
                }
                &:after {
                    border-color: $black;
                }
            }
        }
    }
    .buttons {
        display: block;
        text-align: right;
        padding: rem-calc(15);
        a {
            display: inline;
        }
        .button-logo {
            height: 30px;
        }
    }

}

.page-header {
    background: no-repeat left center;
    background-size: cover;
    position: relative;
    height: 200px;
    @include breakpoint(medium) {
        height: 250px;
    }
    @include breakpoint(large) {
        height: 350px;
    }
    .header-logo {
        position: absolute;
        left: 0;
        top: 0;
        height: 100px;
        @include breakpoint(medium) {
            height: 180px;
        }
        @include breakpoint(large) {
            height: 265px;
        }
    }
    .header-image {
        width: 100%;
    }
    .pay-off {
        //@include box-shadow( 0px 0px 2px rgba($primary-color,.4));
        position: absolute;
        color: $white;
        font-style: italic;
        text-shadow:  2px 2px rgba($primary-color,.4);
        left: 60px;
        bottom: 20px;
        font-size: rem-calc(20);
        @include breakpoint(medium) {
            left: 80px;
            bottom: 20px;
            font-size: rem-calc(25);
        }
        @include breakpoint(large) {
            left: 106px;
            bottom: 20px;
            font-size: rem-calc(35);
        }

    }
}

.page-menu {
    position: relative;
    height: 50px;
    .hamburger-menu {
        margin: 10px 0;
        @include hamburger($black, $dark-gray, 40px, 29px, 5px, 3);
    }
    ul.submenu {
        margin: 0 0 rem-calc(20) 0;
        padding: 0;
        li {
            border-left: 1px solid $black;
            display: inline;
            line-height: 50px;
            list-style: none;
            margin: rem-calc(5);
            padding-left: rem-calc(10);
            font-size: 14px;
            text-transform: uppercase;
            &:first-of-type {
                border-left: 0;
            }
            i {
                margin: 0 rem-calc(10) 0 0;
            }
            a {
                color: $black;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.page-content {
    min-height: 50%;
    margin: rem-calc(70) auto 0 auto;
    img {
        $block-radius: 25px;
        border-radius: $block-radius;
        -webkit-border-radius: $block-radius;
        -moz-border-radius: $block-radius;
        margin: rem-calc(20) auto;
    }
    .left {
        blockquote {
            color: $black;
            font-size: inherit;
            font-style: italic;
            text-align: left;
            border: 0;
            margin: 0 0 rem-calc(25) 0;
            strong {
                color: $black;
                font-weight: 200;
            }
            @include breakpoint(medium) {
                font-size: inherit;
            }
            @include breakpoint(large) {
                font-size: inherit;
            }
        }
    }
    .right {
        blockquote {
            color: $black;
            font-size: 22px;
            font-style: italic;
            text-align: center;
            border: 0;
            margin: rem-calc(20) 0 rem-calc(40) 0;
            @include breakpoint(medium) {
                font-size: 22px;
            }
            @include breakpoint(large) {
                font-size: 30px;
            }
        }
    }
}

.bijles h1,
.bijles h2,
.bijles h3,
.bijles .right blockquote,
.bijles .right blockquote p {
    color: $primary-color-bijles;
}

.dutch-lessons h1,
.dutch-lessons h2,
.dutch-lessons h3,
.dutch-lessons .right blockquote,
.dutch-lessons .right blockquote p {
    color: $primary-color-dutch-lessons;
}

.page-footer-header {
    display: table;
    table-layout: fixed;
    line-height: 16px;
    width: 100%;
    .page-footer-header-spacer {
        display: table-cell;
        width: auto;
        padding: 0;
        margin: 0;
    }
    .page-footer-header-middle {
        display: table-cell;
        width: 155px;
        padding: 0;
        margin: 0;
    }
    .page-footer-header-middle-bijles {
        background: url("../img/footer-bijles.svg") no-repeat bottom center;
        background-size: cover;
    }
    .page-footer-header-middle-dutch-lessons {
        background: url("../img/footer-dutch-lessons.svg") no-repeat bottom center;
        background-size: cover;
    }
}

.page-footer {

    ul {
        margin: 0 0 rem-calc(20) 0;
        padding: 0;
        li {
            list-style: none;
            margin: rem-calc(10) 0;
            padding: 0;
            font-size: 14px;
            i {
                margin: 0 rem-calc(10) 0 0;
            }
            a {
                color: $black;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
